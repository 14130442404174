import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'post-comment-items',
  templateUrl: './post-comment-items.component.html',
  styleUrls: ['./post-comment-items.component.scss'],
})
export class PostCommentItemsComponent implements OnInit {
  @Input() itemType = 'Pat';
  @Input() readonly;
  @Input() showReported;
  @Input() item: any;
  @Input() comments: any;
  @Input() countComment: number;
  @Input() inappropriatePresent: boolean;
  @Input() isObjComment: boolean;
  showMore = true;
  count: number = 2;

  constructor() {}

  ngOnInit(): void {
    if (this.countComment <= 2) {
      this.showMore = false;
    }
    if (this.inappropriatePresent) {
      this.comments = this.comments.filter(function (obj) {
        return obj.IsDeleted == true;
      });
    }
  }
  ngDoCheck() {
    if (this.countComment <= 2 || this.comments.length <= 2) {
      this.showMore = false;
    } else {
      this.showMore = true;
    }
    if (this.count >= this.countComment || this.count >= this.comments.length) {
      this.showMore = false;
    }
  }

  seemorecomment() {
    this.count = this.count + 2;
    if (this.count >= this.countComment) {
      this.showMore = false;
    }
  }

  commentDeleted(comment) {
    const commIdx = this.comments.findIndex((x) => x.Id === comment.Id);
    if (commIdx !== -1) {
      this.comments.splice(commIdx, 1);
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('dropdown') dropdown: BsDropdownDirective;
  @Output() getResponse = new EventEmitter();

  searchText: string;
  serachTextChanged: Subject<string> = new Subject<string>();
  @Input() data: any[] = [];
  allDataList: any[] = [];
  allFilteredDataList: any[] = [];
  @Input() disabled = false;
  @Input() selectionMultiple: boolean = false;
  @Input() label: string;
  @Input() isRequired: boolean = false;
  @Input() searchManually: boolean = false;
  @Input() isEmpInfo: boolean = true;
  @Input() isAnalytic: boolean = false;

  throttle = 50;
  scrollDistance = 2;
  scrollUpDistance = 2;
  pageStart = 1;
  pageSize = 50;
  curentList = [];
  isMobile: boolean;
  showMandatory = false;
  searchable = false;
  isOpened = false;
  subsc: Subscription;
  userInfo: any;

  constructor(
    private _renderer: Renderer2,
    public coreService: CoreService,
    private ref: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private keyboard: Keyboard,
    private eRef: ElementRef
  ) {
    this.serachTextChanged
      .pipe(
        debounceTime(1000), // wait 500ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe((model) => {
        this.searchText = model;
        this.filter();
      });
  }

  ngOnInit() {
    this.userInfo = this.coreService.getUserInfo();
    this.initDataList();
    this.subsc = this.coreService.getEmpClearFilter().subscribe((emps) => {
      if (emps) {
        this.clearSelectionById(emps);
      }
    });
  }

  ngOnDestroy(): void {
    this.subsc.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.setReadOnly();
  }

  setReadOnly() {
    if (this.searchManually && !this.searchable) {
      this._renderer.setAttribute(
        this.searchInput.nativeElement,
        'readonly',
        'true'
      );
    }
  }

  initDataList() {
    if (!this.data?.length) {
      return;
    }
    let selectedEmployees = [];
    if (!this.isAnalytic) {
      selectedEmployees = this.data.filter((x) => x.isPreselect);
    } else {
      selectedEmployees = JSON.parse(JSON.stringify(this.data[0]));
    }
    this.setSelected([selectedEmployees]);
    this.allDataList = JSON.parse(JSON.stringify(this.data));
    this.allFilteredDataList = JSON.parse(JSON.stringify(this.data));
    this.getEmployees();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.initDataList();
    }
  }

  ngAfterViewChecked() {
    this.isMobile = this.deviceService.isMobile();
  }

  onScrollDown() {
    this.pageStart++;
    this.getEmployees();
  }

  async searchChanged(text: string) {
    if (!this.searchManually) {
      // this.searchText = '';
      const textted = await JSON.parse(JSON.stringify(text));
      this.serachTextChanged.next(textted);
    } else {
      const textted = await JSON.parse(JSON.stringify(text));
      this.serachTextChanged.next(textted);
    }
  }

  getEmployees(isSearched = false) {
    let start = (this.pageStart - 1) * this.pageSize;
    let end = this.pageStart * this.pageSize;
    let pagedData = this.allFilteredDataList.slice(start, end);

    if (this.pageStart == 1) {
      this.curentList = pagedData;
    } else {
      this.curentList = this.curentList.concat(pagedData);
    }

    if (this.curentList.length > 0 && this.selectionMultiple) {
      this.curentList.sort((a: any, b: any) => {
        return a.selected ? -1 : 0;
      });
    }
    this.setValue(false, isSearched);
  }

  setSelected(selectedTeams_Employees: any) {
    selectedTeams_Employees.forEach((element: any) => {
      let index = this.data.findIndex((item: any) => item.Id === element.Id);
      if (index >= 0) {
        this.data[index]['selected'] = true;
      }
    });

    for (let key of Object.keys(selectedTeams_Employees)) {
      // console.log(key);
    }

    if (this.data.length > 0 && this.selectionMultiple) {
      this.data.sort((a: any, b: any) => {
        return a.selected ? -1 : 0;
      });
    }
  }

  getCombinations(chars) {
    let combinations = [];
    chars.forEach((char, i) => {
      let word = '';
      this.buildWord(word + ' ' + char, [i], chars, combinations);
    });
    return combinations;
  }

  buildWord(word, usedIndexes, chars, combinations) {
    // if (word.trim().split(' ').length > 1) {
    combinations.push(word.trim());
    // }
    chars.forEach((char, i) => {
      if (usedIndexes.indexOf(i) === -1) {
        let newUsedIndexesArray = Array.from(usedIndexes);
        newUsedIndexesArray.push(i);
        this.buildWord(
          word + ' ' + char,
          newUsedIndexesArray,
          chars,
          combinations
        );
      }
    });
  }

  filter() {
    this.pageStart = 1;
    let search = this.searchText.toLowerCase();

    let combinations =
      search.split(' ').length > 1
        ? this.getCombinations(search.split(' '))
        : [search];

    this.allFilteredDataList = this.allDataList.filter((item: any) => {
      let matched: boolean = false;
      combinations.forEach((element: string) => {
        // Se inserisco 2 parole devo cercare le combinazioni con almeno 2 parole
        const lengthSearch = search.split(' ').length;
        const lengthCombination = element.split(' ').length;

        if (lengthCombination >= lengthSearch) {
          if (
            item &&
            (item.Name || item?.Team) &&
            (item?.Name?.toLowerCase().includes(element.toLowerCase()) ||
              item?.Team?.toLowerCase().includes(element.toLowerCase()))
          ) {
            matched = true;
          }
        }
      });
      return matched;
    });
    this.ref.detectChanges();
    this.getEmployees(true);
  }

  SendToBack() {
    // if (!this.almoustOneSelected() && this.isRequired) return;
    this.getResponse.emit({
      selected: this.allDataList.filter((item: any) => item.selected),
      originalList: this.allDataList,
    });
  }

  almoustOneSelected() {
    return (
      this.allFilteredDataList.filter((item: any) => item.selected).length > 0
    );
  }

  deselectItem(item: any) {
    item['selected'] = false;
    this.curentList.forEach((employee: any) => {
      if (employee.Id === item.Id) {
        employee['selected'] = false;
      }
    });
    this.curentList.sort((a, b) => {
      return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
        ? 1
        : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
          ? -1
          : 0;
    });
    this.allDataList.forEach((employee: any) => {
      if (employee.Id === item.Id) {
        employee['selected'] = false;
      }
    });
    this.allDataList.sort((a, b) => {
      return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
        ? 1
        : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
          ? -1
          : 0;
    });
    this.allFilteredDataList.forEach((employee: any) => {
      if (employee.Id === item.Id) {
        employee['selected'] = false;
      }
    });
    this.allFilteredDataList.sort((a, b) => {
      return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
        ? 1
        : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
          ? -1
          : 0;
    });
    this.setValue(false);
    this.dropdown.hide();
  }

  selectItem(item: any) {
    if (!this.selectionMultiple) {
      if (item.selected) {
        this.deselectItem(item);
      } else {
        item['selected'] = item['selected'] ? false : true;
        this.keyboard.hide();
        this.unselectOthers(item.Id);
      }
      this.allDataList.sort((a: any, b: any) => {
        return a.selected ? -1 : 0;
      });
    } else {
      item['selected'] = item['selected'] ? false : true;
      this.keyboard.hide();
      // salvo nella lista generale la selezione che ho fatto, così dopo il filter mi rimane selezionato.
      this.allDataList.forEach((rec: any) => {
        if (rec.Id === item.Id) {
          rec['selected'] = item.selected;
        }
      });

      // Riordino la lista in base al selezionato
      if (this.selectionMultiple) {
        this.allDataList.sort((a: any, b: any) => {
          return a.selected ? -1 : 0;
        });
      }
      this.setValue(false);
    }
    this.dropdown.hide();
  }

  getSelected() {
    return this.allDataList.filter((item: any) => item.selected);
  }

  unselectOthers(id) {
    this.allDataList.forEach((e) => {
      if (e.Id != id) {
        e.selected = false;
      } else {
        e.selected = true;
      }
    });
    this.allDataList.sort((a, b) => {
      return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
        ? 1
        : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
          ? -1
          : 0;
    });
    this.allFilteredDataList.forEach((e) => {
      if (e.Id != id) {
        e.selected = false;
      } else {
        e.selected = true;
      }
    });
    this.allFilteredDataList.sort((a, b) => {
      return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
        ? 1
        : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
          ? -1
          : 0;
    });
    this.curentList.forEach((e) => {
      if (e.Id != id) {
        e.selected = false;
      } else {
        e.selected = true;
      }
    });
    this.curentList.sort((a, b) => {
      return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
        ? 1
        : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
          ? -1
          : 0;
    });
  }
  clearSelectionById(ids) {
    this.allDataList.forEach((e) => {
      e.selected = ids.includes(e.Id);
    });
    this.allFilteredDataList.forEach((e) => {
      e.selected = ids.includes(e.Id);
    });
    this.curentList.forEach((e) => {
      e.selected = ids.includes(e.Id);
    });
    this.setValue(false);
  }
  onOutsideClick(event: any): void {
    const element = event.target.classList;
    if (element && element.length && !element.contains('categoryImg')) {
      this.dropdown.hide();
      this.curentList = this.allDataList;
    }
  }

  onHidden() {
    this.isOpened = false;
    this.searchable = false;
    this.setValue(true);
    this.SendToBack();
    this.setReadOnly();
  }

  onShown(): void {
    this.showMandatory = false;
    this.isOpened = true;
    this.setReadOnly();
  }

  async setValue(isClosed: boolean, isSearched = false) {
    if (!isSearched) {
      const selected = this.getSelected();
      if (selected.length == 1) {
        this.searchText = await selected[0].Name;
      } else if (selected.length > 1) {
        this.searchText =
          `${selected.length} ` +
          this.coreService
            .getTranslation(
              'COMPANY_PERFORMANCE_REVIEW.REVIEWEES_TABLE.EMPLOYESS_SELECTED'
            )
            .toLowerCase();
      } else {
        this.searchText = '';
      }
      if (isClosed) {
        this.showMandatory = !!!selected.length;
      }
    }
  }

  canSearch(event) {
    event.stopPropagation();
    this.searchable = true;
    this.searchText = '';
    this.searchInput.nativeElement.focus();
    this._renderer.removeAttribute(this.searchInput.nativeElement, 'readonly');
  }
  dropdownOpen() {
    this.dropdown.isOpen = true;
  }
}
